/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import TruckAssetIcon from './img/truckAssets.svg';
import NoTelemetryIcon from './img/featureIcon.svg';
import CloseButtonIcon from './img/closeButton.svg';
import ToolTips from '../tooltip/ToolTips';
import {
    LearnMore, ManageAssetButton,
} from '../buttonComponent/ButtonComponent';
import { APIGetDevices } from '../../config/restAPI/FetchAPI';
import './assetDetailsCard.css';

export default function EmptyCardMore(props) {
    const {
        assetSelection, closeButton, emptyCardValue,
    } = props;
    // console.log('assetSelection :', assetSelection);
    const navigate = useNavigate();
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const [hover, setHover] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deviceData, setDeviceData] = useState({});
    const textElementRef = useRef();

    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });

    const searchingValue = {
        oid: assetSelection.uuid || emptyCardValue.uuid,
        pageNumber: 1,
    };

    const fetchDevice = async (params) => {
        try {
            const result = await APIGetDevices(setLoading, token, agencyID, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                const [{
                    oid, assetName, assetType, deviceSerial, tags, deviceType, lastUpdated, dataUsage, dataLimit, description,
                }] = data;
                if (data.length > 0) {
                    const assetDetails = {
                        id: oid,
                        deviceSN: deviceSerial,
                        assetName: assetName !== null ? (assetName) : (null),
                        assetType: [assetType],
                        tags: tags !== undefined ? (tags) : [],
                        dataUsage: {
                            usage: dataUsage !== null ? (dataUsage) : (null),
                            limit: dataLimit,
                        },
                        lastUpdated: {
                            date: lastUpdated !== null ? (moment(lastUpdated).format('DD MMM YYYY')) : (null),
                            time: lastUpdated !== null ? (`${moment(lastUpdated).format('h:mm:ssa')} GMT${moment(lastUpdated).format('ZZ').replace('00', '').replace('0', '')}`) : (null),
                        },
                        description: description || '',
                    };
                    navigate('/inventory/assetdevices/configuration', { replace: true, state: assetDetails });
                    setLoading(false);
                }
            }
        } catch (err) {
            console.log('fetch error', err);
        }
    };

    // (this for elipsis event listener)
    const compareSize = () => {
        if (textElementRef && textElementRef.current && textElementRef.current.scrollWidth && textElementRef.current.clientWidth) {
            const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
            setHover(compare);
        }
    };

    // compare once and add resize listener on "componentDidMount" (this for elipsis event listener)
    useEffect(() => {
        compareSize();
        window.addEventListener('resize', compareSize);
    }, []);

    // remove resize listener again on "componentWillUnmount" (this for elipsis event listener)
    useEffect(() => () => {
        window.removeEventListener('resize', compareSize);
    }, []);

  return (
    <div className={isMobileOrTablet ? 'asset-card-detail-container-mobile' : 'asset-card-detail-container'}>
        <div className="asset-card-header-container-no-telemetry">
            <div className="asset-card-device-container">
                <img loading="lazy" src={TruckAssetIcon} alt="truck-icon" />
                <ToolTips text={assetSelection.name || emptyCardValue.name} disbledHover={!hover}>
                    <div ref={textElementRef} className="asset-card-empty-header-text">{assetSelection.id === null ? ('-') : (assetSelection.id || emptyCardValue.id)}</div>
                </ToolTips>
                <img loading="lazy" src={CloseButtonIcon} alt="close-button" style={{ cursor: 'pointer' }} className="asset-card-close-button" onClick={(e) => closeButton(e)} />
            </div>
            <div className="asset-device-detail-container">{`Device - ${assetSelection.name || emptyCardValue.name}`}</div>
            {/* <div className="asset-device-detail-container">Device -</div> */}
        </div>
        <div className="asset-card-content-container-no-telemetry">
            <div className="asset-card-empty-img">
                <img loading="lazy" src={NoTelemetryIcon} alt="address-icon" />
            </div>
            <div className="asset-card-title-no-telemetry">No telemetry data</div>
            <div className="asset-card-text-no-telemetry" style={{ marginBottom: '1rem' }}>
                If this asset / device has just been added, it may have not sent its first payload.
            </div>
            <div className="asset-card-text-no-telemetry">
                If this is an asset / device that has been inactive for too long, its latest payload may have been deleted as part of Horizon’s retention policy.
            </div>
        </div>
        <div className="asset-card-button-no-telemetry">
            <LearnMore label="Learn More" />
            <ManageAssetButton label="Manage" btnClick={() => fetchDevice(searchingValue)} />
        </div>
    </div>
  );
}
