import React, {
    useState, useContext, useEffect, useRef,
} from 'react';
import { ThemeProvider, createTheme, CircularProgress } from '@mui/material';
import _cloneDeep from 'lodash.clonedeep';
import Cookies from 'js-cookie';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import './NotificationHistory.css';
import NotifConfigTab from '../../../component/notificationConfigTab/notificationConfigTab';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import Title from '../../../component/title/Title';
import NotificationList from '../../../component/notificationList/notificationList';
import { WebsocketContext } from '../../../config/reducer/Websocket';
import { APIGetAlerts } from '../../../config/restAPI/FetchAPI';
import { APIPatchAlerts } from '../../../config/restAPI/PatchAPI';
import { NotificationContext } from '../../../context/NotificationContext';

const loadTheme = createTheme({
    palette: {
        amcolor: {
            main: '#365A6D',
        },
    },
});

const NotificationHistory = () => {
    const payloadContext = useContext(WebsocketContext);
    const [state, setState] = useState();
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState, idleState, setIdleState] = useOutletContext();
    const [notifFilter, setNotifFilter] = useState('all');
    const [notification, setNotification] = useState();
    const [loading, setLoading] = useState(false);
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const userID = Cookies.get('_id');

    const {
        setChannel, data: websocketData, newPayloadId, selectAsset, closeAssetCard, selectedAsset, CloseConnection,
    } = payloadContext;

    // const [unreadNotifications, setUnreadNotifications] = useState(data?.filter((x) => x.read === false).length);

    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

    const {
        data,
        setData,
        unreadCount,
      } = useContext(NotificationContext);

    const handleChecked = (id) => {
        setSelectedCheckboxes((prevState) => ({
         ...prevState,
         [id]: !prevState[id],
        }));
    };

    const notificationNavigationList = [
        {
            text: 'Notifications History', value: 1, path: '/notifications/history', enabled: true,
        },
        {
            text: 'Alert Configurations', value: 2, path: '/notifications/configuration', enabled: true,
        },
    ];

    const CustomLoader = () => (
        <div style={{ padding: '24px', margin: 'auto', height: 'auto' }}>
            <ThemeProvider theme={loadTheme}>
                <CircularProgress color="amcolor" variant="indeterminate" />
            </ThemeProvider>
        </div>
    );

    const navigationList = [
        {
            text: 'All',
            value: 'all',
            enabled: true,
        },
        {
            text: 'Unread',
            value: 'unread',
            enabled: true,
        },
        {
            text: 'Archived',
            value: 'archived',
            enabled: false,
        },
    ];

    useEffect(() => {
        if (newPayloadId) {
            if (websocketData?.type === 'alert') {
                const cloneData = _cloneDeep(data);
                cloneData.unshift(websocketData.data);
                setData([...cloneData]);
            }
        }
    }, [newPayloadId]);

    // useEffect(() => {
    //     if (data) {
    //         setUnreadNotifications(data.filter((x) => x.read === false).length);
    //     }
    // }, [data]);

    const fetchAlerts = async (params) => {
        try {
            const result = await APIGetAlerts(setLoading, token, agencyID, params);
            const notifications = result?.data?.data;
            setData(notifications);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const markAlertAsRead = async () => {
        try {
            if (Object.values(selectedCheckboxes).every((v) => v === false)) {
                const params = {
                    input: {
                        read: true,
                    },
                };
                const result = await APIPatchAlerts(token, setLoading, agencyID, params);
                if (result?.data?.status === 200) {
                    setLoading(false);
                    fetchAlerts();
                }
            } else {
                const keys = Object.keys(selectedCheckboxes).filter((k) => selectedCheckboxes[k]);
                const params = {
                    input: {
                        oids: keys,
                        read: true,
                    },
                };
                // console.log(keys);
                const result = await APIPatchAlerts(token, setLoading, agencyID, params);
                if (result?.data?.status === 200) {
                    setLoading(false);
                    fetchAlerts();
                }
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    // const listInnerRef = useRef();

    // const handleScroll = (event) => {
    //     console.log('scroll');
    //     if (listInnerRef.current) {
    //       const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
    //       if ((scrollTop + clientHeight + 1) === scrollHeight) {
    //         setCurrPage(currPage + 1);
    //         // This will be triggered after hitting the last element.
    //         // API call should be made here while implementing pagination.
    //       }
    //     }
    //   };

    // useEffect(() => {
    //     const fetchAlertsAPI = async () => {
    //         const params = {
    //             pageNumber: currPage,
    //         };
    //         const result = await APIGetAlerts(setLoading, token, agencyID, params);
    //         if (!result.data.data.length) {
    //             setWasLastList(true);
    //             return;
    //         }
    //         setPrevPage(currPage);
    //         setData([...data, ...result.data.data]);
    //         setLoading(false);
    //     };
    //     if (!wasLastList && prevPage !== currPage) {
    //         fetchAlertsAPI();
    //     }
    // }, [currPage, wasLastList, prevPage, data]);

    useEffect(() => {
        fetchAlerts();
    }, []);

    return (
        <div className="notification-history-container">
            <ListNavigationTab list={notificationNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="notification-history-header">
                <Title title="Notifications History" subtitle="View all available notifications" titleSize="1.875rem" subtitleSize="1rem" lineHeight="2.375rem" subtitleLineHeight="1.5rem" />
                <div className="asset-performance-tabs">
                    <NotifConfigTab
                      color="#FFFF"
                      types={navigationList}
                      module="notifications"
                      notifFilter={notifFilter}
                      setNotifFilter={setNotifFilter}
                      notification={notification}
                      setNotification={setNotification}
                      setData={setData}
                      data={data}
                      fetchAlerts={fetchAlerts}
                      markAsRead={markAlertAsRead}
                      selectedCheckboxes={selectedCheckboxes}
                      unread={unreadCount}
                    />
                </div>
            </div>
            <div className="notification-history-body">
                {loading ? (
                    <CustomLoader />
                ) : (
                    <NotificationList data={data} selectedCheckboxes={selectedCheckboxes} setSelectedCheckboxes={setSelectedCheckboxes} handleChecked={handleChecked} />
                )}
            </div>
        </div>
    );
};

export default NotificationHistory;
