/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// import PropTypes from 'prop-types';
import { AuthContext } from '../../config/reducer/Auth';
// import Navigation from '../navigation/Navigation';
import AccountMobile from '../account/AccountMobile';
// import Title from '../title/Title';
import { WebsocketContext } from '../../config/reducer/Websocket';
import SystemStatus from '../systemStatus/SystemStatus';
import ListNavigationSidebar from '../navigation/ListNavigationSidebar';
import MenuPopup from '../menuPopup/MenuPopup';
import useComponentVisible from '../../config/outsideClick/useComponentVisible';
import './footer-navigation.css';

export default function FooterNavigationMobile(props, { history }) {
    const {
        navigationState, setNavigationState, isAdmin, setNavigationClick, cookiesUpdated,
    } = props;

    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const { switchModalShow, setSwitchModalShow } = props;
    const navigate = useNavigate();
    const [menuState, setMenuState] = useState(false);
    const [name, setName] = useState('');

  const getLatestCookies = () => {
    setName(Cookies.get('name'));
  };

  useEffect(() => { getLatestCookies(); }, []);
  useEffect(() => {
        if (cookiesUpdated) {
            getLatestCookies();
        }
    }, [cookiesUpdated]);

    const payloadContext = useContext(WebsocketContext);
    const context = useContext(AuthContext);
    const { logout } = context;
    const { CloseConnection } = payloadContext;
    const sessionID = Cookies.get('SessionID');
    const channel = Cookies.get('agency-id');
    // const name = Cookies.get('name');
    const listAgency = localStorage.getItem('listAgency') ? JSON.parse(localStorage.getItem('listAgency')) : [];

    const footerNavigationList = [
        // {
        //     text: 'Notifications', value: 'notifications', enabled: true,
        // },
        // {
        //     text: 'Support', value: 'support', path: '/support', enabled: true,
        // },
        {
            text: 'Settings', value: 'settings', path: '/settings/profile', enabled: true,
        },
    ];

    const btnLogout = async () => {
        const response = await axios.delete(`${process.env.REACT_APP_WEBSOCKET_LOGOUT}?session=${sessionID}`, null, { credentials: 'same-origin' });
        if (response.status === 200) {
            CloseConnection('logout');
            logout();
            navigate('/login', { replace: true });
        }
    };

    return (
        <div className="footer-navigation-container">
            <div className="footer-nav">
                {/* <ListNavigationSidebar list={footerNavigationList} navigationState={navigationState} setNavigationState={setNavigationState} setNavigationClick={setNavigationClick} /> */}
                <MenuPopup page="footerNavigationMobile" history={history} logout={logout} setSwitchModalShow={setSwitchModalShow} switchModalShow={switchModalShow} agencyList={listAgency} />
            </div>
                    {/* <SystemStatus label="Back to the old experience" isAdmin={isAdmin} /> */}
            <AccountMobile
              name={name}
              agency={channel}
              switchModalShow={switchModalShow}
              setSwitchModalShow={setSwitchModalShow}
              history={history}
              logout={btnLogout}
              agencyList={listAgency}
              cookiesUpdated={cookiesUpdated}
            />
        </div>
    );
}
