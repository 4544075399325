export const onDataChangeWS = (websocketData, mapRefs) => {
    try {
        if (websocketData && websocketData.latitude && websocketData.longitude) {
            mapRefs?.current?.easeTo({
                center: [websocketData.longitude, websocketData.latitude],
                duration: 1000,
                zoom: 20,
                essential: true,
            });
        }
    } catch (err) {
        console.log('websocket error', err);
    }
};
