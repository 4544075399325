/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import _cloneDeep from 'lodash.clonedeep';
import { useMediaQuery } from 'react-responsive';
import { Popover } from 'react-tiny-popover';
// import PropTypes from 'prop-types';
import { AuthContext } from '../../config/reducer/Auth';
// import Navigation from '../navigation/Navigation';
import Account from '../account/Account';
// import Title from '../title/Title';
import { WebsocketContext } from '../../config/reducer/Websocket';
import { NotificationContext } from '../../context/NotificationContext';
import SystemStatus from '../systemStatus/SystemStatus';
import ListNavigationSidebar from '../navigation/ListNavigationSidebar';
import MenuPopup from '../menuPopup/MenuPopup';
import NotificationFeed from '../NotificationFeed/NotifcationFeed';
import useComponentVisible from '../../config/outsideClick/useComponentVisible';
import { APIGetAlerts } from '../../config/restAPI/FetchAPI';
import './footer-navigation.css';

const ALERT_TYPE_MAP = {
  101: 'Geofence Alert',
};

export default function FooterNavigation(props, { history }) {
    const {
        navigationState, setNavigationState, isAdmin, setNavigationClick, cookiesUpdated,
    } = props;

    const [showMenu, setShowMenu] = useState(false);

    const isDefault = useMediaQuery({
        query: '(min-width: 1024px)',
    });
    const isMobileOrTablet = useMediaQuery({
        query: '(max-width: 1023px)',
    });

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const { switchModalShow, setSwitchModalShow } = props;
    const navigate = useNavigate();
    const [menuState, setMenuState] = useState(false);
    const [name, setName] = useState('');

    const [loading, setLoading] = useState(false);
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const role = Cookies.get('role');

  const getLatestCookies = () => {
    setName(Cookies.get('name'));
  };

  useEffect(() => { getLatestCookies(); }, []);
  useEffect(() => {
        if (cookiesUpdated) {
            getLatestCookies();
        }
    }, [cookiesUpdated]);

    const payloadContext = useContext(WebsocketContext);
    const context = useContext(AuthContext);
    const { logout } = context;
    const {
      data,
      setData,
    } = useContext(NotificationContext);
    // const { CloseConnection } = payloadContext;
    const {
      setChannel, data: websocketData, newPayloadId, selectAsset, closeAssetCard, selectedAsset, CloseConnection,
    } = payloadContext;
    const sessionID = Cookies.get('SessionID');
    const channel = Cookies.get('agency-id');
    // const name = Cookies.get('name');
    const listAgency = localStorage.getItem('listAgency') ? JSON.parse(localStorage.getItem('listAgency')) : [];

    const footerNavigationList = [
        {
            text: 'Notifications', value: 'notifications', path: '/notifications/history', enabled: true,
        },
        // {
        //     text: 'Support', value: 'support', path: '/support', enabled: true,
        // },
        {
            text: 'Settings', value: 'settings', path: '/settings/profile', enabled: true,
        },
    ];

    const btnLogout = async () => {
        const response = await axios.delete(`${process.env.REACT_APP_WEBSOCKET_LOGOUT}?session=${sessionID}`, null, { credentials: 'same-origin' });
        if (response.status === 200) {
            CloseConnection('logout');
            logout();
            navigate('/login', { replace: true });
        }
    };

        const fetchAlerts = async (params) => {
        try {
            const result = await APIGetAlerts(setLoading, token, agencyID);
            const notifications = result?.data?.data;
            setData(notifications);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAlerts();
    }, []);

    useEffect(() => {
      if (newPayloadId) {
          if (websocketData?.type === 'alert') {
              const alertType = ALERT_TYPE_MAP[websocketData.data.type] || 'Unknown Alert';
              const cloneData = _cloneDeep(data);
              cloneData.unshift(websocketData.data);
              setData([...cloneData]);
              // Browser Notifications
              // if (Notification.permission === 'granted') {
              //     const notification = new Notification(`Horizon - ${alertType}`, {
              //         body: websocketData.data.message,
              //         tag: `alert-${newPayloadId}`,
              //     });
              //     notification.onclick = () => {
              //         window.focus();
              //         navigate('/notifications/history', { replace: true });
              //     };
              // } else if (Notification.permission !== 'denied') {
              //     Notification.requestPermission().then((permission) => {
              //         if (permission === 'granted') {
              //             const notification = new Notification(`Horizon - ${alertType}`, {
              //                 body: websocketData.data.message,
              //                 tag: `alert-${newPayloadId}`,
              //             });
              //             notification.onclick = () => {
              //                 window.focus();
              //                 navigate('/notifications/history', { replace: true });
              //             };
              //         }
              //     });
              // }
          }
        }
    }, [newPayloadId]);

    return (
        <div className="footer-navigation-container">
          {window.location.pathname !== '/notifications/history' ? (
            <Popover
              isOpen={showMenu}
              positions={['right']}
              align="end"
              content={(
                <div
                  className="snackbar-shadow"
                  onMouseEnter={() => setShowMenu(true)}
                  onMouseLeave={() => setShowMenu(false)}
                >
                    <NotificationFeed data={data} setData={setData} fetchAlerts={fetchAlerts} />
                </div>
              )}
              containerStyle={{
                // padding: '16px',
                zIndex: '100',
              }}
            >
                <div
                  className="footer-nav"
                >
                    <ListNavigationSidebar
                      data={data}
                      list={footerNavigationList}
                      navigationState={navigationState}
                      setNavigationState={setNavigationState}
                      setNavigationClick={setNavigationClick}
                      showMenu={showMenu}
                      setShowMenu={setShowMenu}
                    />
                </div>
            </Popover>
          ) : (
            <div className="footer-nav">
              <ListNavigationSidebar
                data={data}
                list={footerNavigationList}
                navigationState={navigationState}
                setNavigationState={setNavigationState}
                setNavigationClick={setNavigationClick}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
            </div>
          )}
            <SystemStatus label="Back to the old experience" isAdmin={isAdmin} />
            <Account
              name={name}
              agency={channel}
              switchModalShow={switchModalShow}
              setSwitchModalShow={setSwitchModalShow}
              history={history}
              logout={btnLogout}
              agencyList={listAgency}
              cookiesUpdated={cookiesUpdated}
            />
        </div>
    );
}
